




import Vue from 'vue';
import PublicPlayroomDetail from '@/views/public/playrooms/PublicPlayroomDetail.vue';
import PlayroomPublicService from '@/services/public/PublicPlayroomService';
import PlayroomDetailDto from '@/models/PlayroomDetailDto';

export default Vue.extend({
  components: { PublicPlayroomDetail },
  props: {
    playroomUrlPath: {
      type: String,
    },
  },
  data() {
    return {
      playroom: new PlayroomDetailDto(),
    };
  },
  mounted() {
    this.loadPlayroom();
  },
  methods: {
    loadPlayroom() {
      PlayroomPublicService.getDetailFromCustomUrl(this.playroomUrlPath).then((response) => {
        this.playroom = response.value;
      });
    },
  },
});
